@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(./fonts/RobotoCondensed-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(./fonts/RobotoCondensed-Bold.ttf) format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'), url(./fonts/RobotoCondensed-Light.ttf) format('truetype');
  font-weight: lighter;
}

body {
  margin: 0;
  font-family: 'RobotoCondensed', sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-opened {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
